import { Flex } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";
import React from "react";

import type { Page } from "types/page";

type Props = PropsWithChildren<{ Component: Page }>;

export default function HomeLayout({ children }: Props) {
  return (
    <Flex
      direction="column"
      overflow="auto"
      p={4}
      position="fixed"
      bottom={0}
      top={0}
      left={0}
      right={0}
    >
      {children}
    </Flex>
  );
}
