import { Heading, Text } from "@chakra-ui/react";
import { config } from "react-spring";

import type { AnimationStep } from "./types";

const animation: AnimationStep["animation"] = {
  from: { opacity: 1, transform: "translate3d(0, 100vh, 0)" },
  enter: { transform: "translate3d(0, 0vh, 0)" },
  config: config.gentle,
};

const Logo = () => {
  return (
    <Heading fontSize="150px">
      T
      <Text display="inline" color="brand.500">
        R
      </Text>
      P
    </Heading>
  );
};

const LogoAnimationStep: AnimationStep = { Component: Logo, animation };
export default LogoAnimationStep;
