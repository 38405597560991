import { useRouter } from "next/router";
import { useEffect } from "react";

import LandingAnimation from "components/LandingAnimation";
import HomeLayout from "components/layouts/HomeLayout";
import { selectToken, authActions } from "features/auth/authSlice";
import paths from "paths";
import { useAppDispatch, useAppSelector } from "store/hooks";
import type { Page } from "types/page";

const Root: Page = () => {
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const authorizeRoute = async (authToken: string) => {
    try {
      await dispatch(authActions.auth({ token: authToken })).unwrap();
      router.replace(paths.player);
    } catch (err) {
      router.replace(paths.landing);
    }
  };

  const handleNavigation = () => {
    if (token) {
      authorizeRoute(token);
    } else {
      router.replace(paths.landing);
    }
  };

  return <LandingAnimation onFinishAnimation={handleNavigation} />;
};

Root.Layout = HomeLayout;

export default Root;
