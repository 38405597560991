import { Box } from "@chakra-ui/react";

import type { AnimationStep } from "./types";

const animation = {
  from: { opacity: 1, width: "0vmax", height: "0vmax" },
  enter: { width: "200vmax", height: "200vmax" },
  config: { duration: 1700 },
};

const Color = () => {
  return <Box bg="brand.500" borderRadius="full" boxSize="full" />;
};

const ColorAnimationStep: AnimationStep = { Component: Color, animation };
export default ColorAnimationStep;
