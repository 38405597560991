import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import { animated, useTransition } from "react-spring";

import {
  getLocalBool,
  showStartupAnimationKey,
} from "components/UI/UserInterfaceSettings";

import ColorAnimationStep from "./ColorAnimationStep";
import LogoAnimationStep from "./LogoAnimationStep";
import TitleAnimationStep from "./TitleAnimationStep";

interface LandingAnimationProps {
  onFinishAnimation: () => void;
}

const LandingAnimation = ({ onFinishAnimation }: LandingAnimationProps) => {
  const showAnimation = getLocalBool(showStartupAnimationKey);
  const steps = [LogoAnimationStep, TitleAnimationStep, ColorAnimationStep];
  const [step, setStep] = useState(showAnimation ? 0 : steps.length + 1);

  const [component, setComponent] = useState([steps[0].Component]);
  const [animation, setAnimations] = useState(steps[0].animation);

  const cleanupAnimation = () => {
    onFinishAnimation();
  };

  const transitions = useTransition(component, {
    ...animation,
    leave: { opacity: 0 },
    onRest: () => {
      if (step + 1 >= steps.length) {
        cleanupAnimation();
        return;
      }

      setAnimations(steps[step + 1].animation);
      setComponent([steps[step + 1].Component]);
      setStep(step + 1);
    },
  });

  return transitions((styles, AnimatedItem) => {
    return (
      <Flex
        position="absolute"
        top={0}
        left={0}
        justifyContent="center"
        alignItems="center"
        boxSize="full"
        overflow="visible"
      >
        {showAnimation ? (
          <animated.div style={{ ...styles, position: "absolute" }}>
            <AnimatedItem />
          </animated.div>
        ) : null}
      </Flex>
    );
  });
};

export default LandingAnimation;
