// Place all paths for routing here. This should match the layout of the
// `pages` directory.
//
// This will avoid typos and ease any refactoring of routes.
// For dynamic routes, use a function to generate the href.
//
// Feel free to nest paths, but use `home` if a nested route has an index page.
//
// We don't need api paths in here because they should never need to be duplicated.
const paths = {
  root: "/",
  home: "/home",
  landing: "/landing",
  // TODO no current route to about page. Determine route in design.
  about: "/about",
  account: "/account",
  // TODO no current route to admin page. Determine route in design.
  admin: {
    home: "/admin",
    artists: {
      home: "/admin/artists",
      artist: (id: string) => `/admin/artists/${id}`,
    },
    songs: {
      home: "/admin/songs",
      add: "/admin/songs/add",
    },
    users: "/admin/users",
    statistics: "/admin/statistics",
  },
  artist: {
    artist: (id: string) => `/artist/${id}`,
    editArtist: (id: string) => `/artist/edit/${id}`,
    editArtistSongs: (id: string) => `/artist/edit/songs/${id}`,
    editArtistArtwork: (id: string) => `/artist/edit/artwork/${id}`,
  },
  artists: {
    home: "/artists",
    register: "/artists/register",
  },
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    forgotPassword: "/auth/forgotPassword",
    resendEmailVerification: "/auth/resendEmailVerification",
    resetPassword: "auth/resetPassword",
  },
  // TODO no current route to contact page. Determine route in design.
  contact: "/contact",
  library: {
    home: "/library",
    playlists: "/library/playlists",
  },
  player: "/player",
  search: "/search",
  song: (id: string) => `/song/${id}`,
  user: (id: string) => `/user/${id}`,
};

export default paths;
