import { Heading, Text } from "@chakra-ui/react";
import { config } from "react-spring";

import type { AnimationStep } from "./types";

const animation: AnimationStep["animation"] = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  config: { tension: 280, friction: 80 },
};

const Title = () => {
  return (
    <Heading fontSize="37px">
      The{" "}
      <Text display="inline" color="brand.500">
        Rock
      </Text>{" "}
      Program
    </Heading>
  );
};

const TitleAnimationStep: AnimationStep = { Component: Title, animation };
export default TitleAnimationStep;
