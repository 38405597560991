import { Switch, Stack, Box, Flex, Heading } from "@chakra-ui/react";

export const showStartupAnimationKey = "showStartupAnimationKey";
export const showDiscoveryKey = "showDiscoveryKey";
export const showWaveformsKey = "showWaveformsKey";
export const showDeveloperColors = "showDeveloperColors";

interface Props {
  onToggleStartupAnimation: (value: boolean) => void;
  onToggleDiscovery: (value: boolean) => void;
  onToggleWaveforms: (value: boolean) => void;
  onToggleDeveloperColors: (value: boolean) => void;
}

export const getLocalBool = (key: string) => {
  const exists = localStorage.getItem(key);
  if (exists) {
    return JSON.parse(exists);
  } else return false;
};

const setLocalBool = (key: string, value: boolean) => {
  localStorage.setItem(key, JSON.stringify(value));
};
const handleValueToggled = (key: string, onToggle: Function) => {
  const newValue = !getLocalBool(key);
  setLocalBool(key, newValue);
  onToggle(newValue);
};

export default function UserInterfaceSettings({
  onToggleStartupAnimation,
  onToggleDiscovery,
  onToggleWaveforms,
  onToggleDeveloperColors,
}: Props) {
  return (
    <Flex direction="column" alignItems="stretch" gridGap={2} m={2}>
      <Heading>User Interface Settings</Heading>

      <Box padding={"1"} height={800} maxHeight="50vh" my={4} overflowY="auto">
        {
          <Stack>
            <Switch
              defaultChecked={getLocalBool(showStartupAnimationKey)}
              onChange={() =>
                handleValueToggled(
                  showStartupAnimationKey,
                  onToggleStartupAnimation
                )
              }
              colorScheme="brand"
            >
              Startup Animation
            </Switch>

            <Switch
              defaultChecked={getLocalBool(showDiscoveryKey)}
              onChange={() =>
                handleValueToggled(showDiscoveryKey, onToggleDiscovery)
              }
              colorScheme="brand"
            >
              Show Discovery Slider
            </Switch>

            <Switch
              defaultChecked={getLocalBool(showWaveformsKey)}
              onChange={() =>
                handleValueToggled(showWaveformsKey, onToggleWaveforms)
              }
              colorScheme="brand"
            >
              Show Waveforms
            </Switch>
            <Switch
              defaultChecked={getLocalBool(showDeveloperColors)}
              onChange={() =>
                handleValueToggled(showDeveloperColors, onToggleDeveloperColors)
              }
              colorScheme="brand"
            >
              Show Developer Colors
            </Switch>
          </Stack>
        }
      </Box>
    </Flex>
  );
}
